/* Sidebar.css */
.sidebar {
  /* background-color: #2c3e50; */
  color: white;
  padding: 20px;
  width: 250px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: auto;
  font-family: 'Roboto', sans-serif;
  position: fixed;
  top: 0;
  left: 0;
  transform: translateX(-100%);
  transition: transform 0.3s ease;
  z-index: 999;
}

.hamburger-toggle {
  cursor: pointer;
  position: fixed;
  border: 1px solid #c3c3c3;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
  margin-top: 1rem;
  background-color: white;

  top: 10px;
  left: 8px;
  z-index: 1000; /* Maior do que o z-index do sidebar para que fique por cima */
  font-size: 24px;
  /* color: white; */
}

.sidebar.open {
  transform: translateX(0);
}

.sidebar.closed {
  transform: translateX(-100%);
}

.avatar-section {
  text-align: center;
  margin-bottom: 20px;
}


.avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px solid white;
}
.campoFotoPerfil{
  display: flex;
  flex-direction: row;
  /* background-color: red; */
  justify-content: center;
  align-items: center;
}

.edit-avatar {
  display: flex;
  width: 20%;
justify-content: center;
  /* background-color: #34495e; */
  /* background: none; */
  border: none;
  cursor: pointer;
  margin-top: 5px;
  /* margin:auto; */
  color: white;
}

.edit-avatar input[type="file"] {
  display: none;
}

.company-name {
  margin-top: 10px;
  font-weight: bold;
}

.menu-items {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  /* background-color: blue; */
}

.menu-item {
  cursor: pointer;
  display: block;
  align-items: center;
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  /* background-color: aqua; */
  font-size: 0.9rem;
}

.menu-item .menu-icon {
  margin-right: 10px;
}

.dropdown-toggle {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.dropdown-icon {
  margin-left: auto;
}

.dropdown-menu {
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin-top: 10px;
  background-color: #34495e;
  /* width: 100%; Ajusta a largura do submenu para a do .menu-item */
}

.dropdown-item {
  padding: 10px;
  margin-left: 10px;
  font-size: 0.8rem;
  /* background-color: aquamarine; */
}

.logout-section {
  margin-top: auto; /* Empurra para a parte inferior do sidebar */
  padding-top: 20px;
  border-top: 1px solid #34495e;
}

.logout-button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logout-icon {
  margin-right: 10px;
}

@media (max-width: 768px) {
  .sidebar {
    /* width: 60px; Largura reduzida para telas menores */
  }

  .sidebar.open .avatar-section,
  .sidebar.open .company-name,
  .sidebar.open .edit-avatar,
  .sidebar.open .menu-icon,
  .sidebar.open .dropdown-icon,
  .sidebar.open .logout-icon,
  .sidebar.open .menu-item,
  .sidebar.open .logout-section {
    display: block; /* Exibe os itens do menu quando o sidebar está aberto */
  }

  .menu-item,
  .logout-button {
    /* justify-content: center; */
  }
}
