/* Adicione estes estilos ao seu arquivo CSS */
.calendario-container {
    display: flex;
    /* flex-direction: column; */
    /* align-items: flex-start; */
  }
  
  .rbc-calendar {
    width: 100%;
    height: 100vh;
  }

/* Adicione estas regras ao seu arquivo de estilos (Agendamento.css) */
.rbc-btn-group {
    display: flex;
    justify-content: space-around;
    max-width: 400px; /* Ajuste conforme necessário para controlar a largura máxima dos botões */
    margin: 0 auto; /* Centraliza os botões no contêiner */
  }
  
  .rbc-btn-group > button {
    flex: 1;
    max-width: 120px; /* Ajuste conforme necessário para controlar a largura máxima de cada botão */
    margin: 0 5px; /* Espaçamento entre os botões */
  }
  
  
  