/* LoginEstabelecimento.css */

.login-estabelecimento-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100vh;
  }
  
  .logo-container {
    margin-bottom: 20px;
  }
  
  .logo-container img {
    max-width: 100%;
    height: auto;
  }
  
  .login-estabelecimento-form {
    width: 300px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .login-estabelecimento-form {
    width: 300px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  label {
    display: block;
    margin-bottom: 8px;
  }
  
  .login-estabelecimento-form input, .login-estabelecimento-form select {
    width: 100%;
    padding: 8px;
    margin-bottom: 16px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  

  
  .forgot-password {
    display: block;
    text-align: center;
    margin-bottom: 30px;
    /* color: #069; */
    text-decoration: none;
    font-size: 14px;
  }
  
  .form-actions {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .login-estabelecimento-form button {
    /* background-color: #069; */
    width: 100%;
    /* color: #fff; */
    border: none;
    padding: 10px 20px;
    border-radius: 3px;
    cursor: pointer;
    margin-bottom: 10px;
  }
  
  span {
    /* color: #069; */
    cursor: pointer;
    font-size: 14px;
  }


  @media only screen and (max-width: 600px) {
    .login-estabelecimento-form {
      width: 80%;
    }
  }


  /*MODAL NIVEL 2*/
.modal-LoginCliente {
  display: flex;
  flex-direction: column;
  background-color: #171717;
  border-radius: 8px;
  overflow: hidden;
  width: 70%; /* Largura do modal */
  max-width: 500px; /* Largura máxima do modal */
  height: 90%; /* Altura máxima do modal */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.modal-background-LoginCliente {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1100; /* Z-index alto para garantir que o modal esteja sempre acima do conteúdo */
}

.modal-conteudo-LoginCliente {
  display: flex;
  flex-direction: column;
  flex:1;
  /* height: calc(100% - 100px); Subtrai a altura da seção do fundo */
  height: 100%;
  padding: 0;
  overflow-y: auto;
  /* background-color: #ffa500; */
}

.modal-fundo-LoginCliente {
  background-size: cover;
  background-position: center;
  height: 0px; /* Altura da seção do fundo (pode ser ajustada conforme necessário) */
  position: relative;
}

.modal-fundo-LoginCliente button {
  position: absolute;
  top: 10px;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  border: none;
  cursor: pointer;
}
  