.bodySignup{
  margin: 20px 0;
}


.formulario-galeria-estabelecimento {
  font-family: 'Roboto', sans-serif;
  /* max-width: 500px; */
  max-width: 100%;
  margin: 0;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-sizing: border-box;
}

.formulario-galeria-estabelecimento label {
  display: block;
  margin-bottom: 10px;
}

.switch-container-lstGaleria {
  display: flex;
  flex-direction: row;
}

.switch-lstGaleria {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 34px;
  margin-left: 10px;
}

.switch-lstGaleria input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider-lstGaleria {
  position: absolute;
  cursor: pointer;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 24px;
}

.slider-lstGaleria:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider-lstGaleria {
  background-color: #2196F3;
}

input:checked + .slider-lstGaleria:before {
  transform: translateX(26px);
}

.submit-button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #0056b3;
}

.grid-container-galeria-estabelecimento {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  font-family: 'Roboto', sans-serif;
}
.body-galeria-img{
  width: 100%;
}

.label-galeria-img{
  /* margin-top: 20px; */
}

.loader {
  width: 15px;
  height: 15px;
  border: 5px solid #fff;
  border-bottom-color: #ff3d00;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.galeria-img-item{
  width: 300px;
  height: 230px;
  border-radius: 8px;
}
@media (max-width: 600px) {
  .formulario-galeria-estabelecimento {
    width: 95%;
    padding: 10px;
  }

  .grid-container-galeria-estabelecimento {
    grid-template-columns: 1fr;
  }

  /* .body-galeria-img{
    width: 90%;
  } */

  .galeria-img-item{
    width: 265px;
    height: 210px;
  }
}
