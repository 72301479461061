.container {
  font-family: 'Roboto', sans-serif;
}

.body {
  margin: 20px 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  /* justify-content: space-around; */
  height: auto;
  overflow-x: auto; /* Adicione essa propriedade para rolagem horizontal */
}

.topo h3{
  margin-bottom: 20px;
}

.topo p{
  margin: 0 0 15px 0;
}

.form-lstCol-estab {
width: auto;
padding: 20px 10px 10px 10px;
border-radius: 8px;
background-color: rgba(0,0,0,0.15);
display: flex;
/* justify-content: center; */
align-items: center;
}

.form-lstCol-estab label{
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-lstCol-estab span{
  margin: 0 10px 0 0;
}

.form-lstCol-estab input{
  /* margin: 0 10px 10px 0; */
  height: 25px;
  padding: 5px;
  width: auto;
}

.form-lstCol-estab .btnVincular{
  width: auto;
  padding: 4px 5px;
  color: white;
  border-radius: 5px;
  margin: 0 0 0 20px;
  border: 1px solid #c3c3c3;
  background-color: #2196F3;
}

.searchIcon{
  padding: 4px 8px;
  border-radius: 5px;
  border: 1px solid #c3c3c3;
  /* margin-right: 15px; */
  margin: 0 20px;
  background-color: #2196F3;
}

.lista {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* width: 300px; */
  padding: 10px 5px;
  margin: 10px;
  border: 1px solid #c3c3c3;
  border-radius: 10px;
  width: calc(33.33% - 20px); /* Defina 33.33% para 3 colunas */
  box-sizing: border-box;
}

.btnTrash{
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  color: #069;
  width: auto;
}

.btnTrash:hover{
  cursor:pointer;
  color: orangered;
}

.lista img {
  width: 80px;
  height: 80px;
}

.nome {
  margin: 10px 0 5px 0;
}

.email {
  margin: 10px 0 5px 0;
}

.telefone {
  margin: 10px 0 5px 0;
}

.switch-container-lstCol {
  display: flex;
  flex-direction: row;
}

.switch-lstCol-estab {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 32px;
  /* margin-left: 10px; */
}

.switch-lstCol-estab input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider-lstCol-estab {
  position: absolute;
  cursor: pointer;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 24px;
}

.slider-lstCol-estab:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider-lstCol-estab {
  background-color: #2196f3;
}

input:checked + .slider-lstCol-estab:before {
  transform: translateX(25px);
}


/* Media query para tela média (tablet) */
@media screen and (max-width: 768px) {
  .lista {
    width: calc(50% - 20px); /* Defina 50% para 2 colunas */
  }
}

/* Media query para tela pequena (celular) */
@media screen and (max-width: 480px) {
  .lista {
    width: calc(100% - 20px); /* Defina 100% para 1 coluna */
  }

  .form-lstCol-estab{
    width: 100%;
    display: block;
    justify-content: center;
    align-items: center;
  }

  .form-lstCol-estab label{
    display: flex;
    flex-direction: column;
  }
  
  .form-lstCol-estab .btnVincular{
    width: auto;
    padding: 4px 5px;
    color: white;
    border-radius: 5px;
    border: 1px solid #c3c3c3;
    background-color: #2196F3;
    /* margin: -10px 0 0 0; */
  }
  
  .searchIcon{
    padding: 4px 8px;
    border-radius: 5px;
    border: 1px solid #c3c3c3;
    /* margin-right: 15px; */
    margin: 0 0 10px 0;
    background-color: #2196F3;
  }
}