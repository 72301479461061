.bodySignup {
  margin: 20px 0;
}

.formulario-perfil {
  font-family: "Roboto", sans-serif;
  /* max-width: 500px; */
  max-width: 100%;
  margin: 0;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-sizing: border-box;
}

.formulario-perfil label {
  display: block;
  margin-bottom: 10px;
}

.formulario-perfil input[type="text"],
.formulario-perfil input[type="email"],
.formulario-perfil input[type="number"],
.formulario-perfil textarea,
.formulario-perfil select,
.masked-input{
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.formulario-perfil textarea {
  resize: none;
}

.formulario-perfil-select {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.submit-button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #0056b3;
}

.categorias-list-PERFIL-ESTABELECIMENTO {
  /* Estilize conforme necessário para a lista de serviços */
  margin-bottom: 20px;
  padding: 10px;
  margin-top: 5px;
  border-radius: 5px;
  border: 1px solid teal;
}

.categorias-list-PERFIL-ESTABELECIMENTO ul {
  list-style: none;
}

.categorias-list-PERFIL-ESTABELECIMENTO input[type="checkbox"] {
  width: 30px;
}

.loader {
  width: 15px;
  height: 15px;
  border: 5px solid #fff;
  border-bottom-color: #ff3d00;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 600px) {
  .formulario-perfil {
    width: 100%;
    padding: 10px;
  }
}
