/* Dashboard.css */
.dashboard {
  display: flex;
  flex-direction: column;
}

.main-content {
  display: flex;
  height: calc(100vh - 50px); /* Altura do Navbar */
}

.content-area {
  flex-grow: 1;
  padding: 20px 20px 20px 50px;
  /* background-color: #fff; */
  /* Adicione mais estilos para a área de conteúdo */
}
