.planos-page {
    padding: 20px;
    margin-bottom: 100px;
    text-align: center;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.titulo-planos {
    font-size: 3em;
    margin-bottom: 50px; /* Margem aumentada para dar mais espaçamento abaixo do título */
    color: #fff;
}

.planos-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    width: 100%;
}

.card-planos {
    background: linear-gradient(135deg, #d1d1e9, #e3eeff); /* Novo degradê mais suave */
    border-radius: 15px;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
    color: #333;
    padding: 20px;
    width: 300px;
    text-align: center;
    position: relative;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card-planos:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.3);
}

.card-planos h2 {
    font-size: 1.5em;
    margin-bottom: 10px;
}

.plan-card-span{
    padding-left: 5px;
    font-size: 1.2rem;
    color:#069;
    font-weight: bold;
}

.card-planos .preco {
    font-size: 1.2em;
    margin-bottom: 15px;
    font-weight: bold;
    color: #ff6347; /* Cor de preço alterada para destacar */
}

.card-planos p {
    font-size: 0.8em;
    margin-bottom: 20px;
}

.card-planos ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.card-planos ul li {
    background-color: rgba(0, 0, 0, 0.1); /* Cor mais suave para os itens da lista */
    margin: 5px 0;
    padding: 10px;
    border-radius: 5px;
    color: #333; /* Cor do texto mais escura para melhor contraste */
}

/* Selos */
.selo {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 50px;
    height: 50px;
}

.selo circle {
    fill: #ccc;
}

.selo text {
    fill: #fff;
    font-size: 3rem;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Sombra para melhorar a legibilidade */
}

.selo.bronze circle {
    fill: #cd7f32;
}

.selo.prata circle {
    fill: #c0c0c0;
}

.selo.ouro circle {
    fill: #ffd700;
}

.selo.diamante circle {
    fill: #b9f2ff;
}

@media (max-width: 768px) {
    .planos-container {
        flex-direction: column;
        align-items: center;
    }

    .card-planos {
        width: 90%;
    }
}
