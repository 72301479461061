/* VinculaServico.css */

.vincula-servicos {
    /* Estilize conforme necessário para o contêiner principal */
    margin: 0 0 0 10px;
    font-family: 'Roboto', sans-serif;
  }

  .vincula-servicos h3{
    margin: 0 0 10px 0;
  }

  .descricao{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
    /* background-color: rgba(0,0,0,0.15);     */
  }

  .vincula-servicos hr{
    border-block-start: none;
    border-block-end-color: rgba(0,0,0,0.2);
    margin-bottom: 20px;
  }

  .vincula-servicos p{
    margin-bottom: 5px;
  }
  .vincula-servicos p:last-of-type{
    /* margin-bottom: 20px; */
  }
  
  .collaborators-list {
    list-style: none;
    padding: 0;
  }
  
  .colaborador-info {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .colaborador-info img {
    width: 50px; /* Ajuste conforme necessário */
    height: 50px; /* Ajuste conforme necessário */
    margin-right: 10px;
  }
  
  .info-direita {
    flex: 1;
  }
  
  button{
      cursor: pointer;
  }
  .services-toggle-button {
    border: none;
    cursor: pointer;
  }
  
  .services-list {
    margin-bottom: 20px;
    padding: 10px;
    margin-top: 5px;
    border-radius: 5px;
    border: 1px solid teal;
  }
  
 .vincula-servicos ul li{
    list-style: none;
    margin-bottom: 30px;
    padding-bottom: 10px;
    border-bottom: 0.2px solid;
    border-bottom-color: rgba(255,255,255,0.2);
  }

  .vincula-servicos ul li ul li {
    margin-bottom: 10px;
  }

  .chk{
    margin-right: 5px;
  }

  .services-list input[type="checkbox"]{
    width: 30px;
  }