/* styles.css para EstablishmentList */
.establishment-list {
  display: grid;
  grid-template-columns: repeat(1, 1fr); /* 1 coluna por padrão */
  gap: 20px;
  padding: 20px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.establishment-card {
  border: 1px solid #ddd;
  /* border-radius: 8px; */
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.image-container {
  position: relative;
}

.establishment-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.like-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #fff;
}

.distance {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.distance-icon {
  margin-right: 5px;
}

.info-container {
  padding: 15px;
}

.establishment-name {
  font-size: 1.5em;
  margin: 0;
}

.rating {
  margin: 10px 0;
  font-size: 1.2em;
  color: #ffa500;
}

.address, .city-country {
  margin: 0;
  color: #666;
}

/* Responsividade */
@media (min-width: 600px) {
  .establishment-list {
    grid-template-columns: repeat(2, 1fr); /* 2 colunas para tablets pequenos */
  }
}

@media (min-width: 900px) {
  .establishment-list {
    grid-template-columns: repeat(3, 1fr); /* 3 colunas para tablets maiores */
  }
}

@media (min-width: 1200px) {
  .establishment-list {
    grid-template-columns: repeat(4, 1fr); /* 4 colunas para desktops */
  }
}

@media (min-width: 1800px) {
  .establishment-list {
    grid-template-columns: repeat(6, 1fr); /* 6 colunas para telas grandes */
  }
}


/*ESTABELECIMENTO UNICO*/
/* Reset de estilos básicos */
.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Z-index alto para garantir que o modal esteja sempre acima do conteúdo */
  overflow-y: auto;
}

.modal {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 8px;
  overflow: hidden;
  width: 70%; /* Largura do modal */
  max-width: 500px; /* Largura máxima do modal */
  height: 90%; /* Altura máxima do modal */
  max-height: 90%; /* Altura máxima do modal */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  overflow-y: auto; /* Adiciona rolagem vertical quando necessário */

}

.modal-fundo {
  background-size: cover;
  background-position: center;
  height: 100px; /* Altura da seção do fundo (pode ser ajustada conforme necessário) */
  position: relative;
}

.modal-fundo button {
  position: absolute;
  top: 10px;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  cursor: pointer;
}

.modal-fundo .curtir {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 10px;
  /* background-color: rgba(0, 0, 0, 0.5); */
  color: white;
  border: none;
  cursor: pointer;
}

.modal-conteudo {
  display: flex;
  flex-direction: column;
  /* height: calc(100% - 100px);  */
  height: auto;
  padding: 10px;
  overflow-y: auto;
  /* background-color: #ffa500; */
}

.modal-conteudo h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

.estrelas {
  font-size: 18px;
  margin-bottom: 10px;
}

.endereco {
  margin-bottom: 10px;
  font-size: 0.9rem;
}

.endereco p {
  margin-bottom: 5px;
}

.menu {
  margin-top: 10px;
}

.menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap; /* Permite que os itens do menu quebrem em várias linhas */
}

.menu ul li {
  cursor: pointer;
  padding: 5px 10px;
  color: #fff;
  font-size: 16px;
  transition: all 0.3s ease;
  background-color: #069;
  border-radius: 10px;
  margin: 5px 4px;
}

.menu ul li:hover {
  background-color: rgba(0, 102, 153, 0.815);
}

.box-padrao {
  padding: 10px;
  width: 100%;
  /* height: calc(100% - 50px);  */
  /* height: 300px; */
  /* height: 100%; */
  height: auto;
  /* max-height:320px ; */
  overflow-y: auto; /* Adiciona scroll vertical quando necessário */
}


/* Responsividade para telas menores */
@media (max-width: 768px) {
  .modal {
    width: 80%; /* Reduz a largura do modal para telas menores */
  }

  .modal-fundo {
    height: 100px; /* Ajusta a altura do fundo para telas menores */
  }
 
  .modal-fundo {
    height: 100px; /* Ajusta a altura do fundo para telas menores */
  }

  .modal-fundo2-agendamento{
    height: 30px;
  }

  .modal-conteudo {
    padding: 10px; /* Reduz o padding do conteúdo para telas menores */
  }

  .menu ul li {
    flex-basis: 30%; /* Faz com que os itens do menu ocupem metade da largura em telas menores */
    text-align: center; /* Centraliza o texto dos itens do menu */
  }
}



/*MODAL NIVEL 2*/
.modal2 {
  display: flex;
  flex-direction: column;
  background-color: #171717;
  border-radius: 8px;
  overflow: hidden;
  width: 70%; /* Largura do modal */
  max-width: 500px; /* Largura máxima do modal */
  height: 90%; /* Altura máxima do modal */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.modal-background2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001; /* Z-index alto para garantir que o modal esteja sempre acima do conteúdo */
}

.modal-conteudo2 {
  display: flex;
  flex-direction: column;
  flex:1;
  /* height: calc(100% - 100px); Subtrai a altura da seção do fundo */
  height: 100%;
  padding: 0;
  overflow-y: auto;
  /* background-color: #ffa500; */
}

.modal-fundo2-agendamento {
  background-size: cover;
  background-position: center;
  height: 30px; /* Altura da seção do fundo (pode ser ajustada conforme necessário) */
  position: relative;
}

.modal-fundo2-agendamento button {
  position: absolute;
  top: 10px;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  border: none;
  cursor: pointer;
}

/*GALERIA*/
.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 16px;
  padding: 16px;
}

.gallery-item img {
  width: 100%;
  height: 130px;
  object-fit: cover;
  cursor: pointer;
}

.modal-galeria_img {
  display: flex;
  flex-direction: column;
  background-color: #171717;
  border-radius: 8px;
  overflow: hidden;
  width: 70%; /* Largura do modal */
  /* max-width: 500px; Largura máxima do modal */
  height: auto; /* Altura máxima do modal */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.modal-background-galeria_img {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1100; /* Z-index alto para garantir que o modal esteja sempre acima do conteúdo */
}

.modal-conteudo-galeria_img {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  /* align-items: center; */
  width: 100%;
  height: 100%;
  padding: 0;
  overflow-y: auto;
}

.modal-fundo-galeria_img {
  background-size: cover;
  background-position: center;
  height: 0; /* Altura da seção do fundo (pode ser ajustada conforme necessário) */
  position: relative;
}

.modal-fundo-galeria_img button {
  position: absolute;
  top: 10px;
  padding: 10px;
  font-size: 1rem;
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  border: none;
  cursor: pointer;
  z-index: 1002;
}

.slick-prev, .slick-next {
  z-index: 1000;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  color: white;
}

.slick-prev:hover, .slick-next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.slick-prev {
  left: 10px;
}

.slick-next {
  right: 10px;
}

.slick-slide img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  max-height: 80vh;
  object-fit: contain;
}

@media (min-width: 768px) {
  .gallery {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  }
}

/*OFERTA*/
.oferta-lista-img{
  width: 100%;
  height: 210px;
  background-color: white;
  border-radius: 5px;
}

.oferta-lista-img img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
