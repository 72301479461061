/* styles.css (ou seu arquivo de estilos) */
.news-feed {
    max-width: 100%;
    margin: 0 auto;
    padding: 20px;
    /* background-color: #f7f7f7; */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    overflow-y: scroll;
    height: auto;
  }

  .news-feed h2{
    margin-bottom: 20px;
  }
  
  .news-feed h3 {
    /* color: #333; */
    font-size: medium;
  }
  

/* News.css (ou o seu arquivo de estilos renomeado) */
.news-item {
    margin-bottom: 20px;
    padding: 15px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: box-shadow 0.3s ease;
  
    &:hover {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }
  }
  
  .news-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
  
  .date-arrow-container {
    display: flex;
    align-items: center;
  }
  
  .date {
    /* color: #666; */
    font-size: 14px;
  }
  
  .arrow-divider {
    margin: 0 10px; /* Espaçamento entre a data e a seta */
    border-right: 1px solid #ddd;
    height: 100%;
  }
  
  .dropdown-arrow {
    font-size: 20px;
    transition: transform 0.3s ease;
  }
  
  .dropdown-arrow.open {
    transform: rotate(180deg);
  }
  
  .news-details {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
  }
  
  .divider {
    margin: 10px 0;
    border-bottom: 1px solid #ddd;
  }

  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .pagination button {
    background-color: #007bff;
    color: white;
    border: 1px solid #007bff;
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 4px;
    margin: 0 5px;
  }
  
  .pagination button:hover {
    background-color: #0056b3;
  }
  
  .pagination button:focus {
    outline: none;
  }
  
  .pagination .active button {
    background-color: #0056b3;
  }
  
  
  @media (max-width: 768px) {
    /* Estilos para telas menores (responsivas) */
    .news-header {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .date-arrow-container {
      margin-top: 5px; /* Ajuste o espaço conforme necessário */
    }
  
    .arrow-divider {
      margin: 5px 0;
      border-right: none;
      border-bottom: 1px solid #ddd;
      width: 100%;
    }
  
    .date,
    .arrow-divider,
    .dropdown-arrow {
      margin-left: 0;
    }
  
    .dropdown-arrow {
      margin-top: 5px; /* Ajuste o espaço conforme necessário */
    }

    .pagination {
        /* flex-direction: column; */
        align-items: center;
      }
    
      .pagination button {
        margin: 5px;
      }
  }
  