.cupom-table-adm .botao-editar,
.cupom-table-adm .botao-salvar,
.cupom-table-adm .botao-excluir {
  margin-right: 5px;
  cursor: pointer;
  border: none;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
}

.cupom-table-adm .editavel input[type="text"],
.cupom-table-adm .editavel input[type="number"],
.cupom-table-adm .editavel select {
  color: black; 
  width: calc(100% - 16px); 
  box-sizing: border-box; 
}

.cupom-table-adm .botao-editar {
  background-color: #007bff;
}

.cupom-table-adm .botao-salvar {
  background-color: #28a745;
}

.cupom-table-adm .botao-excluir {
  background-color: #dc3545;
}

.add-button,
.close-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 4px;
}

.add-button:hover,
.close-button:hover {
  background-color: #0056b3;
}

.cad-button {
  background-color: #ff9101;
  color: white;
  border: none;
  padding: 10px 15px;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 4px;
}

.cad-button:hover {
  background-color: #ff9101d0;
}

.rem-button {
  background-color: #ff0000;
  color: white;
  border: none;
  padding: 10px 15px;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 4px;
}

.rem-button:hover {
  background-color: #ff0000d8;
}
