.perfil-container {
  padding: 20px;
  background-color: var(--background);
}
.btn-pass{
  background-color: rgba(64,64,64,1);
  border: none;
  width: 20px;
}

.perfil-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.perfil-icon {
  background: var(--bgButtonCard);
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}

.perfil-topo-title {
  font-size: 1rem; /* 16px */
  color: var(--primaryText);
}

.perfil-body {
  padding: 20px;
  background-color: var(--cardBackground);
  border-radius: 10px;
}

.perfil-description {
  font-size: 0.75rem; /* 12px */
  margin-bottom: 20px;
  color: var(--primaryText);
}

.perfil-scroll {
  flex: 1;
  overflow-y: scroll;
}

.perfil-info-container {
  padding-bottom: 50px;
}

.perfil-form-title {
  font-size: 0.875rem; /* 14px */
  margin-bottom: 20px;
  color: var(--primaryText);
}

.perfil-bloco {
  margin-bottom: 10px;
}

.perfil-label {
  font-size: 0.75rem; /* 12px */
  font-weight: bold;
  margin-bottom: 5px;
  color: var(--primaryText);
}

.perfil-input-group {
  display: flex;
  align-items: center;
}

.perfil-input-group-senha {
  display: flex;
  align-items: center;
}

.perfil-input-senha {
  flex: 1;
  padding: 10px;
  border: 1px solid var(--secondary);
  background-color: var(--inputBackground);
  border-radius: 5px;
  font-size: 0.75rem; /* 12px */
  color: var(--primaryText);
}

.perfil-input {
  flex: 1;
  padding: 10px;
  border: 1px solid var(--secondary);
  background-color: var(--inputBackground);
  border-radius: 5px;
  font-size: 0.75rem; /* 12px */
  color: var(--primaryText);
}

.perfil-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.perfil-modal-content {
  background-color: var(--bgButtonCard);
  border-radius: 10px;
  padding: 20px;
  /* text-align: center; */
}

.perfil-warning {
  font-size: 0.75rem; /* 12px */
  color: var(--primaryText);
  margin-bottom: 20px;
}

.perfil-button {
  padding: 10px 20px;
  background-color: var(--button);
  color: var(--primaryText);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.75rem; /* 12px */
}

/* Responsividade */

@media (min-width: 768px) {
  .perfil-topo-title {
    font-size: 1.2rem; /* 20px */
  }

  .perfil-description {
    font-size: 1rem; /* 16px */
  }

  .perfil-form-title {
    font-size: 1rem; /* 16px */
  }

  .perfil-label {
    font-size: 0.875rem; /* 14px */
  }

  .perfil-input-senha,
  .perfil-input {
    font-size: 0.875rem; /* 14px */
  }

  .perfil-warning {
    font-size: 0.875rem; /* 14px */
  }

  .perfil-button {
    font-size: 0.875rem; /* 14px */
  }
}

@media (min-width: 1024px) {
  .perfil-topo-title {
    font-size: 1.5rem; /* 24px */
  }

  .perfil-description {
    font-size: 1.25rem; /* 20px */
  }

  .perfil-form-title {
    font-size: 1.25rem; /* 20px */
  }

  .perfil-label {
    font-size: 1rem; /* 16px */
  }

  .perfil-input-senha,
  .perfil-input {
    font-size: 1rem; /* 16px */
  }

  .perfil-warning {
    font-size: 1rem; /* 16px */
  }

  .perfil-button {
    font-size: 1rem; /* 16px */
  }
}
