.formulario-servico-cadastro {
  font-family: 'Roboto', sans-serif;
  max-width: 100%;
  /* max-width: 500px; */
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-sizing: border-box;
}

.formulario-servico-cadastro label {
  display: block;
  margin-bottom: 10px;
}

.formulario-servico-cadastro input[type="text"],
.formulario-servico-cadastro textarea,
.formulario-servico-cadastro input[type="number"],
.formulario-servico-cadastro .form-control {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.formulario-servico-cadastro textarea {
  resize: none;
}

/* .switch-container {
  margin-bottom: 10px;
} */

.switch-container-srvCad {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.switch-srvCad {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 34px;
  /* background-color: #007bff; */
  margin-left: 10px;
}

.switch-srvCad input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider-srvCad {
  position: absolute;
  cursor: pointer;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 24px;
}

.slider-srvCad:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider-srvCad {
  background-color: #2196F3;
}

input:checked + .slider-srvCad:before {
  transform: translateX(26px);
}

.submit-button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #0056b3;
}

/* Responsividade */
@media (max-width: 600px) {
  .formulario-servico-cadastro {
    width: 100%;
    padding: 10px;
  }
}
