/* PrincipalE.css */
/* PrincipalE.css */
.principalE-container {
    padding: 1rem 1rem 1rem 1rem;
    margin-top: -60px;
  }
  
  .cards-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-between;
  }
  
  .card {
    flex: 1 1 calc(25% - 1rem); /* Cada card terá 25% da largura do container menos a gap */
    padding: 1rem;
    border-radius: 0.5rem;
    /* background-color: #f7f7f7; */
    text-align: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .card h3 {
    margin: 10px 0;
  }
  
  .card p {
    font-size: 1.5em;
    font-weight: bold;
  }
  
  .novidades-graficos-container {
    display: flex;
    margin-top: 20px;
    width: 100%;
  }
  
  .novidades, .graficos {
    flex: 1;
    /* padding: 20px; */
  }
  
  /* Responsividade com media queries */
@media (max-width: 768px) {
    .cards-container {
      justify-content: center;
    }
  
    .card {
      flex-basis: calc(50% - 1rem); /* Em telas menores, cada card terá 50% da largura do container */
    }
  
    .novidades-graficos-container {
      flex-direction: column;
    }
  }
  
  @media (max-width: 480px) {
    .card {
      flex-basis: 100%; /* Em telas muito pequenas, cada card ocupará 100% da largura do container */
    }
  }
  