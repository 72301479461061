.formulario-colaborador {
  max-width: 100%;
  /* max-width: 500px; */
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  font-family: 'Roboto', sans-serif;
}

.formulario-colaborador label {
  display: block;
  margin-bottom: 10px;
  color: #333;
  font-weight: bold;
}

.formulario-colaborador input,
.formulario-colaborador select,
.formulario-colaborador .form-control {
  width: 100%;
  padding: 8px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.formulario-colaborador button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.formulario-colaborador button:hover {
  background-color: #0056b3;
}

/* Estilos existentes */

@media (max-width: 600px) {
  .formulario-colaborador {
    width: 100%;
    padding: 10px;
    margin: 0 10px;
  }

  .formulario-colaborador input,
  .formulario-colaborador select,
  .formulario-colaborador .form-control {
    padding: 8px;
  }

  .formulario-colaborador button {
    padding: 10px;
  }
}

