/* HomePage.css */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  scroll-behavior: smooth;
  background-color: #171717;
}

.home{
  overflow-x: hidden;
 width: 100%;
 height: 100%;
}


/* SEARCH PANEL*/
.corpoSearchHome{
  margin: auto;
  /* margin-bottom: 35px; */
  z-index: 1;
  /* background-color: #1d21248a; */
  background-image: url('../../assets/images/bg_search.jpg');
  padding: 2rem;
}

/* ESTABELECIMENTO SECTION*/
.estabelecimentoList{
  width: 100%¨;
  padding: 2rem;
  background-color: #fff;
}

/* Slider styles */
.slider-section {
  margin: auto;
  margin-bottom: 35px;
  z-index: 1; /* Deve ser menor que o z-index do menu hambúrguer */
}

.slide {
  position: relative;
}

.slide img {
  width: 100%;
  height: auto;
}

.slide-text {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 1rem;
  width: 100%;
  text-align: center;
  border-radius: 5px;
}

/* Plans section styles */
.plans-section {
  text-align: center;
  padding: 2rem;
}

.plans-header h2 {
  font-size: 4em;
  margin-bottom: 1rem;
}

.cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  margin-top: 50px;
}

.card {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 1rem;
  text-align: center;
  flex: 1;
  min-width: 200px;
  position: relative; /* Para posicionar os selos */
  margin-bottom: 30px; /* Adiciona mais espaço abaixo de cada card */
}

.selo {
  width: 100px;
}

/* Estilos para os cards */
.card .selo {
  position: absolute;
  top: -30px;
  right: -15px;
  /* width: 100px; Ajuste conforme necessário */
}

.features-list {
  list-style: none;
  padding: 0;
  margin-top: 15px;
}

/* Estilos adicionais para os botões dos cards */
.choose-plan {
  background-color: rgba(0, 102, 153, 1);
  /* background-color: #ff9101; */
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
  transition: background-color 0.3s ease;
  width: 100%; /* Faz o botão ocupar toda a largura do card */
}

.choose-plan:hover {
  background-color: rgba(0, 102, 153, 0.8);
}

/* Footer styles */
.footer {
  background-color: #333;
  color: white;
  text-align: center;
}

.footer-top {
  display: flex;
  justify-content: space-around;
  padding: 1rem;
}

.social-links {
  margin: 20px 0;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  /* background-color: #4caf50; */
}

.social-icons {
  /* background-color: #e67e22; */
  flex-direction: row;
  justify-content: space-between;
  margin-top: 15px;
}

.play-store {
  margin-top: 1rem;
}

.footer-bottom {
  background-color: #222;
  padding: 0.5rem;
  font-size: 0.8em;
}
.footer-bottom a {
  text-decoration: none;
}

.footer-bottom a:link {
  color: #e67e22;
}
.footer-bottom a:visited {
  color: #e67e22;
}




.quadroAcessosGrid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
  padding: 20px;
}

.quadro {
  width: 260px;
  height: 220px;
  background-color: #fff;
  border-radius: 10px;
  border: 2px solid;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.quadro h2 {
  color: #333;
  margin: 0;
}

.quantidade {
  font-size: 40px; /* Ajuste conforme necessário */
  font-weight: bold;
  /* color: #007BFF; */
  /* margin-top: 10px; */
}



/* Media Queries */
@media screen and (min-width: 769px) {
  .quadroAcessosGrid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 768px) {

  .card {
    margin-bottom: 2rem; /* Aumenta o espaçamento entre os cards */
  }

  .slider-section {
    margin-bottom: 2rem; /* Aumenta o espaçamento abaixo do slider */
  }

  .plans-header h2 {
    font-size: 1.5em; /* Reduz o tamanho do título para evitar quebras */
  }

  .cards-container {
    flex-direction: column;
  }

  .quadroAcessosGrid {
    grid-template-columns: repeat(2, 1fr);
  }

  .quadro {
    width: 250px;
    height: 250px;
}

.quadro h2{
  font-size: 25px;
}

.quantidade {
    font-size: 50px;
}

  .footer-top {
    flex-direction: column;
  }

  .play-store {
    order: 1; /* Google Play Store below social icons */
  }
}

@media (max-width: 480px) {
  .nav-menu.expanded {
    /* top: 45px; */
    top: 60px; /* Ajusta a posição do menu hambúrguer expandido */
  }

  .card {
    margin-bottom: 30px; /* Aumenta a margem para maior espaçamento */
  }

  /* Ajusta o tamanho do título para telas muito pequenas */
  .plans-header h2 {
    font-size: 1.2em; /* Reduz ainda mais o tamanho do título */
  }

  .slider-section {
    margin: auto;
    margin-bottom: 10px;
    z-index: 1; /* Garante que o slider fique atrás do menu expandido */
  }

  .quadroAcessosGrid {
    grid-template-columns: 1fr;
  }

  .footer-top {
    padding: 0;
  }
}

.gplay {
  width: 250px;
}

/* Estilizando a barra de rolagem para todos os elementos e para o :root (html) */
::-webkit-scrollbar {
  width: 5px; /* Largura da barra de rolagem */
}

::-webkit-scrollbar-track {
  background: rgba(
    0,
    0,
    0,
    0.7
  ); /* Cor de fundo da trilha da barra de rolagem */
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5); /* Cor da barra de rolagem */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Cor da barra de rolagem ao passar o mouse */
}



/* Placeholder para manter o espaço ocupado pela SearchBar */
.search-placeholder {
  height: 170px; /* Altura da SearchBar */
  width: 100%;
  background-color: white;
}

.corpoSearchHome {
  transition: transform 1s ease, top 1s ease, opacity 1s ease;
  opacity: 1;
}

.corpoSearchHome.fixed {
  position: fixed;
  top: 70px; /* Altura do navbar */
  width: 100%;
  z-index: 999;
  background-color: white;
}

.corpoSearchHome.hidden {
  opacity: 0;
  transform: translateY(-20px); /* Pode ajustar a distância da transição conforme necessário */
}

.show-searchbar-button {
  position: fixed;
  top: 70px; /* Ajustado para ficar logo abaixo do navbar */
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  background-color: #007bff;
  color: white;
  border: none;
  font-size: 0.7rem;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  transition: top 1s ease;
}

.show-searchbar-button:hover {
  background-color: #0056b3;
}

.hide-searchbar-button {
  position: fixed;
  top: 70px; /* Ajustado para ficar logo abaixo dos filtros da barra de pesquisa fixa */
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  background-color: #007bff;
  color: white;
  border: none;
  font-size: 0.7rem;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  transition: top 1s ease;
}

.hide-searchbar-button:hover {
  background-color: #0056b3;
}

