.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 50px;
  font-family: 'Arial', sans-serif;
  /* background-color: palevioletred; */
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  /* background-color: blue; */
}

.header h2 {
    font-size: 24px;
  /* color: #fff; */
  /* background-color: blueviolet; */
}

.body {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 30px;
  /* background-color: #f8f8f8; */
}

.servico {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  /* background-color: aquamarine; */
}

.data {
}

.valor {
}

.status{
    margin-top: 10px;
}

.status span{
    padding: 3px 5px;
    /* background-color: aqua; */
}

.cards {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-top: 20px;
  /* background-color: chartreuse; */
}

.cliente, .colaborador {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 45%;
  border: 1px solid #c3c3c3;
  border-radius: 10px;
  padding: 10px;
}

.cliente img, .colaborador img {
  width: 100px;
  height: 100px;
  margin-bottom: 5px;
}

.cliente p, .colaborador p{
    margin-top: 3px;
}

.cliente h4, .colaborador h4 {
    font-size: 15px;
    color: #333;
    margin-bottom: 10px;
  }

.btnGroup{
    width: 100%;
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    justify-content: space-evenly;
}

.button {
    font-size: 16px;
    padding: 10px 15px;
    color: white;
    border: 1px solid #333;
    border-radius: 5px;
    background-color: darkred;
    /* Outras propriedades */
  }

  .button:hover{
    background-color: red;
    cursor: pointer;
  }

  .buttonOK {
    font-size: 16px;
    padding: 10px 15px;
    color: white;
    border: 1px solid #333;
    border-radius: 5px;
    background-color: darkgreen;
    /* Outras propriedades */
  }

  .buttonOK:hover{
    background-color: green;
    cursor: pointer;
  }


  /* Estilos para telas pequenas (por exemplo, largura menor que 600px) */
@media screen and (max-width: 600px) {
  .cards {
    flex-direction: column;
  }

  .cliente, .colaborador {
    margin-top: 10px;
    width: 100%; /* Ocupa toda a largura disponível */
  }
}
