/* TermosDeUsoModal.css */

.termos-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 80%;
    width: 600px; /* Defina a largura máxima do modal */
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    overflow-y: auto;
    max-height: 80vh; /* Defina a altura máxima do modal */
  }
  
  .termos-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .termos-container {
    text-align: justify;
  }
  
  .termos-container h2 {
    color: #333;
  }
  
  .termos-container p {
    margin-bottom: 15px;
  }
  
  .termos-container ul {
    margin-bottom: 15px;
  }
  
  .termos-container li {
    margin-left: 20px;
  }
  
  .termos-container button {
    background-color: #3498db;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  @media only screen and (max-width: 600px) {
    .termos-modal {
      max-width: 90%;
      width: auto;
    }
  }
  