.agendamento_cliente_container {
    background-color: white;
    min-height: 200px;
    padding: 1rem 2rem;
  }
  
  .agendamento_cliente_titulo {
    font-weight: bold;
    font-family: calibri;
    font-size: 1.5rem;
  }
  
  .agendamento_cliente_tabs {
    display: flex;
    justify-content: space-around;
    margin: 1rem 0;
  }
  
  .agendamento_cliente_tab {
    padding: 0.5rem 1rem;
    cursor: pointer;
    border: none;
    background-color: #f0f0f0;
    border-radius: 5px;
  }
  
  .agendamento_cliente_tabActive {
    padding: 0.5rem 1rem;
    cursor: pointer;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
  }
  
  .agendamento_cliente_lista {
    margin-top: 1rem;
  }
  
  .agendamento_cliente_grid {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }
  
  .agendamento_cliente_card {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    flex: 1 1 30%;
    max-width: 30%;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 1rem;
    box-sizing: border-box;
    background-color: rgba(0,0,0,0.05);
  }
  .agendamento_cliente_nome{
    font-size: 1.2rem;
    font-weight: bold;
  }

  .agendamento_cliente_data_agendamento{
    font-size: 0.85rem;
    font-weight: bold;
    margin-top: 5px;
  }
  
  .agendamento_cliente_data_hora_agendamento{
    font-weight: bold;
    font-size: 0.9rem;
  }

  .agendamento_cliente_titulo_servico{
    font-weight: bold;
  }

  .agendamento_cliente_valor{
    font-weight: bold;
  }
  
  .agendamento_cliente_cardContent {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .agendamento_cliente_agendamentoImg {
    margin-bottom: 1rem;
  }
  
  .agendamento_cliente_img {
    border-radius: 5px;
    width: 100%;
  height: 200px;
  object-fit: cover;
  }
  
  .agendamento_cliente_agendamentoInfo {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-bottom: 1rem;
  }
  
  .agendamento_cliente_agendamentoEndereco {
    margin-bottom: 1rem;
  }
  
  .agendamento_cliente_agendamentoFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .agendamento_cliente_cancelButton {
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    background-color: #ff0000;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    align-self: center;
  }
  
  @media (max-width: 768px) {
    .agendamento_cliente_card {
      flex: 1 1 100%;
      max-width: 100%;
    }
  }
  