.grid-container {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  font-family: 'Roboto', sans-serif;
  /* background-color: #007bff; */
}

/* Para telas grandes (acima de 1200px) */
@media (min-width: 1200px) {
  .grid-container {
    grid-template-columns: repeat(auto-fill, minmax(auto, 1fr));
  }
}

/* Para telas médias (entre 600px e 1200px) */
@media (min-width: 600px) and (max-width: 1199px) {
  .grid-container {
    grid-template-columns: repeat(auto, 1fr);
  }
}

/* Para celulares (abaixo de 600px) */
@media (max-width: 599px) {
  .grid-container {
    grid-template-columns: 1fr;
  }
}

.formulario-servico {
  font-family: 'Roboto', sans-serif;
  max-width: 350px;
  margin: 0 auto;
  padding: 20px;
  position: relative;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-sizing: border-box;
}

.formulario-servico label {
  display: block;
  margin-bottom: 10px;
}

.formulario-servico input[type="text"],
.formulario-servico textarea,
.formulario-servico input[type="number"],
.formulario-servico .form-control {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.formulario-servico textarea {
  resize: none;
}

/* .switch-container {
  margin-bottom: 10px;
} */

.switch-container-lstServ {
  display: flex;
  flex-direction: row;
}

.switch-lstServ {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 34px;
  margin-left: 10px;
}

.switch-lstServ input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider-lstServ {
  position: absolute;
  cursor: pointer;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 24px;
}

.slider-lstServ:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider-lstServ {
  background-color: #2196F3;
}

input:checked + .slider-lstServ:before {
  transform: translateX(26px);
}

.submit-button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #0056b3;
}

/* Responsividade */
@media (max-width: 600px) {
  .formulario-servico {
    width: 100%;
    padding: 10px;
  }
}
