body.nav-home-expanded {
    overflow: hidden;
  }
  
  .navbar_home {
    position: fixed; /* Garante que o sidebar seja posicionado corretamente */
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 2rem;
    background-color: rgba(0, 0, 0, 1);
    z-index: 1000; /* Assegura que o navbar esteja acima de outros elementos */
  }
  
  .nav_home_right {
    display: flex;
    align-items: center;
  }
  
  .nav_home_toggle, .nav_home_avatar {
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  
  .nav_home_buttons {
    display: flex;
    gap: 1rem;
  }
  
  .nav_home_item {
    background-color: rgba(255, 255, 255, 0.2);
    /* background-color: rgba(255, 255, 255, 1); */
    color: #FFFFFF;
    padding: 0.8rem 1.2rem;
    border: none;
    text-align: center;
    width: 100%; /* Largura dos botões no sidebar */
    transition: background-color 0.3s ease;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1); /* Linha separadora */
  }
  
  .nav_home_item:hover {
    background-color: rgba(255, 255, 255, 0.4);
  }
  
  /* Estilos responsivos */
  @media (max-width: 768px) {
    .nav_home_buttons {
      display: none; /* Esconda os botões em telas pequenas */
    }
    .nav_home_toggle {
      display: block; /* Exibe o ícone de hambúrguer em telas pequenas */
    }
  }
  
  @media (min-width: 769px) {
    .nav_home_toggle {
      display: none; /* Esconda o ícone de hambúrguer em telas grandes */
    }
    .nav_home_menu {
      display: none; /* Não exibe o menu de navegação em telas grandes */
    }
  }
  
  /* Estilos para o sidebar */
.sidebar_home {
  position: fixed;
  top: 70px; /* Começa abaixo do navbar */
  right: 0; /* Garante que o sidebar fique no canto direito */
  height: calc(100vh - 70px); /* Altura total da janela de visualização menos a altura do navbar */
  width: 250px;
  background-color: rgba(0, 0, 0, 1);
  display: flex;
  flex-direction: column;
  padding: 1rem;
  transition: transform 0.3s ease;
  transform: translateX(100%); /* Inicialmente fora da tela à direita */
  z-index: 999; /* Just below the navbar */
  overflow-y: auto; /* Adiciona rolagem vertical */
  -webkit-overflow-scrolling: touch; /* Suporte para rolagem suave em dispositivos móveis */
}

.sidebar_home.expanded_home {
  transform: translateX(0); /* Sidebar visível */
  /* background-color: aqua; */
  height: calc(100% - 70px);
}

/* Garanta que o conteúdo do sidebar não extrapole a altura disponível */
.sidebar_content {
  height: 100%;
  overflow-y: auto; /* Adiciona rolagem vertical */
  -webkit-overflow-scrolling: touch; /* Suporte para rolagem suave em dispositivos móveis */
}

.avatar_section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
}

.avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.avatar_controls {
  display: flex;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.avatar_controls button {
  background-color: rgba(255, 255, 255, 0.2);
  color: #FFFFFF;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.avatar_controls button:hover {
  background-color: rgba(255, 255, 255, 0.4);
}

.nav_home_item {
  background-color: rgba(255, 255, 255, 0.2);
  color: #FFFFFF;
  padding: 0.8rem 1.2rem;
  border: none;
  text-align: center;
  width: 100%; /* Largura dos botões no sidebar */
  transition: background-color 0.3s ease;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1); /* Linha separadora */
}

.nav_home_item:hover {
  background-color: rgba(255, 255, 255, 0.4);
}
