/* FinancialTable.css */
.content-tabela-pagamento{
  display: flex;
  max-width: 100%;
  overflow: auto;
}
.financial-table-container {
  margin: 0 20px 20px 0;
}

.financial-table-wrapper {
  background-color: bisque;
  display: inline-block; /* Adicionado display: inline-block para envolver a tabela corretamente */
}

.financial-table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;
  white-space: nowrap; /* Impede a quebra de texto em células */
}

.financial-table th, .financial-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.financial-table th {
  background-color: #f2f2f2;
}

.financial-table tbody tr:hover {
  background-color: rgba(0,0,0,0.5);
}

.grupo-btn-pagamento{
  /* background-color: burlywood; */
  display: flex;
  height: 50px;

}

.pagination-pagamento {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination-pagamento button {
  background-color: #007bff;
  color: white;
  border: 1px solid #007bff;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 4px;
  margin: 0 5px;
}

.pagination-pagamento button:hover {
  background-color: #0056b3;
}

.pagination-pagamento button:focus {
  outline: none;
}

.pagination-pagamento .active button {
  background-color: #0056b3;
}

/* Adicionado para tornar a tabela responsiva */
@media (max-width: 730px) {
  .content-tabela-pagamento{
    max-width:100% ;
    margin: 0;
  }

  .financial-table th, .financial-table td {
    font-size: 12px;
    /* width: 300px; */
    /* background-color: #c3c3c3; */
  }
}

@media (max-width: 600px) {
  .content-tabela-pagamento{
    max-width:350px ;
    margin: 0;
  }

  .financial-table th, .financial-table td {
    font-size: 12px;
    width: 100%;
    /* background-color: aqua; */
  }

  .financial-table-wrapper{
    overflow-x: scroll;

  }
}

  