.grid-container-horario-disponivel {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  /* gap: 16px; Ajuste o espaçamento conforme necessário */
  /* background-color: blueviolet; */
  overflow-y: scroll;
  min-height: 400px;
  max-height: 400px;
  padding: 10px 5px 10px 0;
}

.com-position-cad-horas {
  position: relative;
}

.com-position-absolute-cad-horas {
  position: absolute;
  top: 5px;
  right: 5px;
}

.icon-plus-horas {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.detalhes-horario {
  /* background-color: brown; */
  justify-content: center;
  align-items: center;
  width: 90%;
  margin: 0 auto;
}

.horario-disponivel-informacao-lista {
  display: flex;
  align-items: center;
  margin: 5px 0 0 0;
  padding: 5px 0;
  height: 50px;
  border-bottom: 1px solid rgba(53, 59, 61, 0.5);
  border-radius: 5px;
  color: white;
  background-color: rgb(19, 81, 112);
  cursor: pointer;
}

.horario-disponivel-dia-da-semana {
  /* margin: 0 10px; */
  padding-left: 5px;
  width: 25%;
  /* background-color: blueviolet; */
}

.horario-disponivel-informacao-coluna {
  display: flex;
  flex-direction: column;
  /* width: 60%;
    background-color: pink; */
}

.horario-disponivel-informacao-linha {
  display: flex;
  flex-direction: row;
}

.duas-colunas-modal-cad-horario {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.modal-cad-horario-parte {
  width: calc(50% - 30px);
}

.cad-horario-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 50%;
  width: 50%; /* Defina a largura máxima do modal */
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  max-height: 80vh; /* Defina a altura máxima do modal */
}

.cad-horario-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.cad-horario-container {
  text-align: justify;
}

.cad-horario-container h2 {
  color: #333;
}

.cad-horario-container p {
  margin-bottom: 15px;
}

.cad-horario-container ul {
  margin-bottom: 15px;
}

.cad-horario-container li {
  margin-left: 20px;
}

.cad-horario-container button {
  background-color: #3498db;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.centraliza-cad-horario-label{
  display: flex !important;
  width:110px ;
  /* justify-content: center; */
  /* background-color: #3498db; */
  flex-direction: row;
  align-items: center;
}



.form-control{
  width: 100% !important;
  padding: 8px !important;
  margin-bottom: 10px !important;
  border: 1px solid #ccc !important;
  border-radius: 4px !important;
  box-sizing: border-box !important;
}


.react-datepicker-wrapper{
  width: calc(100% - 40px);
  height: 20px;
  /* background-color: pink; */
}


.react-datepicker__input-container{ 
  height: 33px;
  /* background-color: navy; */
 }

.react-datepicker__input-container input{
  margin-left: 40px;
  height: 35px;
  background-color: #3498db;
}

.react-datepicker__month-container{
  /* background-color: chocolate; */
}

/* .react-datepicker-ignore-onclickoutside{
  
} */


@media only screen and (max-width: 600px) {
  .cad-horario-modal {
    max-width: 90%;
    width: 90%;
    /* width: auto; */
  }

  .rd{
    font-size: 0.9rem;
  }
}
