/* styles.css para SearchBar */
.search-container {
    width: 100%;
    padding: 10px;
    background-color: #f8f8f8;
    /* background-color: rgba(0, 0, 0, 0.2); */
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    gap: 10px;
    border-radius:5px;
    height: 100px;
    /* position: absolute; */
  }
  
  .main-search {
    width: 100%;
    display: flex;
    gap: 10px;
  }
  
  .search-input-full {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .search-button {
    padding: 10px 20px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .search-button:hover {
    background-color: #0056b3;
  }
  
  .toggle-filters-button {
    font-size: 0.7rem;
  padding: 10px 10px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .toggle-filters-button:hover {
    background-color: #0056b3;
  }
  
  .filters-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    height: auto;
    border-radius: 5px;
    padding: 10px 20px;
    gap: 10px;
    z-index: 1000;
    background-color: rgba(0,0,0,0.8);
  }
  
  .search-select {
    flex: 1;
    min-width: 150px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  /* Responsividade */
  @media (max-width: 768px) {
    .filters-container {
      flex-direction: column;
    }
  
    .main-search {
      flex-direction: column;
    }
  }
  