.comment-carousel {
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
}

.comment-container {
    background-color: #fff;
    padding: 50px 20px 80px 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
}

.comment-title {
    text-align: center;
    font-size: 2rem;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    margin-bottom: 50px;
    color: #333;
}

.comment-slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 10px;
    box-sizing: border-box;
    width: 100%;
}

.image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-bottom: 10px;
}

.image-wrapper {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    background-color: #f0f0f0;
    background-size: cover;
    background-position: center;
}

.star-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

.star-text {
    font-size: 16px;
    color: #333;
    font-weight: bold;
    text-align: center;
}

.star {
    font-size: 24px;
    color: gold;
}

.comment-text {
    font-size: 16px;
    color: #333;
    padding: 0 10px;
    width: 100%;
    box-sizing: border-box;
}

@media (max-width: 768px) {
    .comment-text {
        font-size: 14px;
    }

    .star-text {
        font-size: 14px;
    }

    .star {
        font-size: 20px;
    }
}

@media (max-width: 480px) {
    .comment-text {
        font-size: 12px;
    }

    .star-text {
        font-size: 12px;
    }

    .star {
        font-size: 18px;
    }
}

.slick-prev, .slick-next {
    width: 40px;
    height: 40px;
    z-index: 1;
}

.slick-prev:before, .slick-next:before {
    display: block; /* Esconde os ícones padrão */
}
