.servico-plano {
  width: 100%;
  overflow-x: auto;
  color: white;
}

.servico-plano table {
  width: 100%;
  border-collapse: collapse;
}

.servico-plano th,
.servico-plano td {
  padding: 8px;
  border-bottom: 1px solid #ddd;
  width: 150px; 
  white-space: nowrap; 
}


.servico-planow tr:nth-child(odd) {
    background-color: rgba(0,0,0,0.5); 
  }


.servico-plano th {
  background-color: #f2f2f2;
  color: #333;
}

.servico-plano .detalhes th,
.servico-plano .detalhes td {
  border-bottom: none;
}

.servico-plano .expandir-detalhes {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.servico-plano .expandir-detalhes span {
  margin-right: 5px;
}

.servico-plano .expandir-detalhes svg {
  margin-left: auto;
}

.servico-plano .botao-editar,
.servico-plano .botao-salvar,
.servico-plano .botao-excluir {
  margin-right: 5px;
  cursor: pointer;
  background-color: #007bff;
  border: none;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
}

.servico-plano .botao-salvar {
  background-color: #28a745;
}

.servico-plano .botao-excluir {
  background-color: #dc3545;
}

.servico-plano .editavel input[type="text"],
.servico-plano .editavel input[type="number"],
.servico-plano .editavel select {
  color: black; 
  width: calc(100% - 16px); 
  box-sizing: border-box; 
}


.modal-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  max-height: 80vh;
  overflow-y: auto;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.form-content {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 13px;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-group-inline {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.form-group-inline .form-group {
  flex: 1;
}

.add-button,
.close-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 4px;
}

.add-button:hover,
.close-button:hover {
  background-color: #0056b3;
}

.cad-button{
  background-color: #ff9101;
  color: white;
  border: none;
  padding: 10px 15px;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 4px;
}

.cad-button:hover {
  background-color: #ff9101d0;
}

.rem-button{
  background-color: #ff0000;
  color: white;
  border: none;
  padding: 10px 15px;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 4px;
}

.rem-button:hover {
  background-color: #ff0000d8;
}

@media (max-width: 600px) {
  .modal-content {
    width: 100%;
    max-width: 90%;
    padding: 15px;
  }
}
