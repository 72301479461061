.containerModalPagamento {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 50px;
  font-family: 'Arial', sans-serif;
  /* background-color: palevioletred; */
}

.headerModalPagamento {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  /* background-color: blue; */
}

.headerModalPagamento h2 {
    font-size: 24px;
  /* color: #fff; */
  /* background-color: blueviolet; */
}

.bodyModalPagamento {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 30px;
  background-color: #f8f8f8;
}

.formulario-perfil-modal-pagamento {
  font-family: 'Roboto', sans-serif;
  /* max-width: 500px; */
  max-width: 100%;
  margin: 0;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-sizing: border-box;
}

.formulario-perfil-modal-pagamento label {
  display: block;
  margin-bottom: 10px;
}

.formulario-perfil-modal-pagamento input[type="text"],
.formulario-perfil-modal-pagamento input[type="email"],
.formulario-perfil-modal-pagamento input[type="number"],
.formulario-perfil-modal-pagamento textarea,
.formulario-perfil-modal-pagamento select {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}


.formulario-perfil-modal-pagamento-select {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.submit-button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #0056b3;
}


.loader {
  width: 15px;
  height: 15px;
  border: 5px solid #fff;
  border-bottom-color: #ff3d00;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 600px) {
  .formulario-perfil-modal-pagamento {
    width: 100%;
    padding: 10px;
  }
}



.descPag-modal{
  display: flex  !important;
  flex-direction: row;
  justify-content: space-between;
  /* background-color: #ff3d00; */
}

.descPag-modal-seta{
  display: flex  !important;

  flex-direction: row;
  justify-content: space-between;
  /* background-color: #ff3d00; */
}

.descPag-modal div{
}













.btnGroupModalPagamento{
    width: 100%;
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    justify-content: space-evenly;
}

.buttonModalPagamento {
    font-size: 16px;
    padding: 10px 15px;
    color: white;
    border: 1px solid #333;
    border-radius: 5px;
    background-color: darkred;
    /* Outras propriedades */
  }

  .buttonModalPagamento:hover{
    background-color: red;
    cursor: pointer;
  }

  .buttonOKModalPagamento {
    font-size: 16px;
    padding: 10px 15px;
    color: white;
    border: 1px solid #333;
    border-radius: 5px;
    background-color: darkgreen;
    /* Outras propriedades */
  }

  .buttonOKModalPagamento:hover{
    background-color: green;
    cursor: pointer;
  }

  .buttonCUPOMModalPagamento {
    font-size: 16px;
    padding: 10px 15px;
    color: white;
    border: 1px solid #333;
    border-radius: 5px;
    background-color: #069;
    /* Outras propriedades */
  }
  .buttonCUPOMFakeModalPagamento {
    font-size: 16px;
    padding: 10px 15px;
    border: 1px solid transparent;
    border-radius: 5px;
    /* Outras propriedades */
  }

  .buttonCUPOMModalPagamento:hover{
    background-color: rgba(0, 102, 153,0.8);
    cursor: pointer;
  }
