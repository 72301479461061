:root {
    --button-media-social-bg-color: rgba(0, 0, 0, 0.1); /* Cor de fundo para o botão */
    --button-media-social-hover-color: rgba(0, 0, 0, 0.1); /* Cor de fundo quando expandido, se necessário */
  }
  
  .share-button-wrapper {
    position: relative;
  }
  
  .share-button {
    border: none;
    background-color: var(--button-media-social-bg-color); /* Usa a variável para a cor de fundo */
    cursor: pointer;
    border-radius: 50%;
    padding: 10px;
    transition: background-color 0.3s ease;
  }
  
  /* Remover a classe .expanded se a cor for a mesma em ambos os estados */
  
  .share-options {
    position: absolute;
    top: -30px; /* Ajuste conforme necessário */
    right: 20px; /* Ajuste conforme necessário */
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0;
    transform: scale(0);
    transform-origin: top right;
    transition: opacity 0.3s ease, transform 0.3s ease;
    background-color: rgba(0,0,0,0.1);
    padding:10px;
    border-radius: 30px;
  }
  
  .share-options.show {
    opacity: 1;
    transform: scale(1);
  }
  
  .share-option {
    display: block;
    margin: 8px 0;
    color: #fff; /* Ajuste a cor conforme necessário */
  }
  
  .share-option svg {
    color: #333; /* Ajuste a cor conforme necessário */
    transition: color 0.3s ease;
  }
  
  .share-option:hover svg {
    color: #007bff; /* Ajuste a cor ao passar o mouse conforme necessário */
  }
  