/* signUpEstabelecimento.css */

.signUp-estabelecimento-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding: 20px 0;
  }
  
  .logo-container {
    margin-bottom: 20px;
  }
  
  .logo-container img {
    max-width: 100%;
    height: auto;
  }
  
  .signUp-estabelecimento-form {
    width: 300px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .signUp-estabelecimento-form {
    width: 300px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  label {
    display: block;
    margin-bottom: 8px;
  }
  
  .signUp-estabelecimento-form input {
    width: 100%;
    padding: 8px;
    margin-bottom: 16px;
    /* border: 1px solid #ccc; */
    border-radius: 3px;
  }
  
  .forgot-password {
    display: block;
    text-align: center;
    margin-bottom: 30px;
    /* color: #069; */
    text-decoration: none;
    font-size: 14px;
  }
  
  .form-actions {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .signUp-estabelecimento-form button {
    /* background-color: #069; */
    width: 100%;
    /* color: #fff; */
    border: none;
    padding: 10px 20px;
    border-radius: 3px;
    cursor: pointer;
    margin-bottom: 10px;
  }
  
  span {
    /* color: #069; */
    cursor: pointer;
    font-size: 14px;
  }

  .categorias-list-signup {
  /* Estilize conforme necessário para a lista de serviços */
  margin-bottom: 20px;
  padding: 10px;
  margin-top: 5px;
  border-radius: 5px;
  border: 1px solid teal;
}

.categorias-list-signup ul{
  list-style: none;
}

.categorias-list-signup ul li {
  display: flex;
  flex-direction: column; /* Alteração aqui para column */
  margin-bottom: 5px;
  border-bottom: 1px rgba(255,255,255,0.2) solid;
  padding: 0;
  /* background-color: aqua; */
}


.categorias-list-signup ul li label {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #069; */
  /* padding: 10px; */
  width: 100%; /* Adição aqui para ocupar a largura total */
}


.categorias-list-signup ul li label input[type="checkbox"] {
  display: flex;
  margin: 0 5px; /* Espaçamento entre os elementos */
  padding: 0;
  width: 20px;
  /* Outros estilos conforme necessário */
}



.categorias-list-signup ul li label span {
  flex: 1; /* Ocupa o restante do espaço disponível */
  /* color: black; */
  width: auto; /* Largura fixa maior para o span */
  margin: 0 5px; /* Espaçamento entre os elementos */
  /* background-color: #586541; */
}



  @media only screen and (max-width: 600px) {
    .signUp-estabelecimento-form {
      width: 80%;
    }
  }
  