/* LoginEstabelecimento.css */

.login-estabelecimento-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100vh;
  }
  
  .logo-container {
    margin-bottom: 20px;
  }
  
  .logo-container img {
    max-width: 100%;
    height: auto;
  }
  
  .login-estabelecimento-form {
    width: 300px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .login-estabelecimento-form {
    width: 300px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  label {
    display: block;
    margin-bottom: 8px;
  }
  
  .login-estabelecimento-form input, .login-estabelecimento-form select {
    width: 100%;
    padding: 8px;
    margin-bottom: 16px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  

  
  .forgot-password {
    display: block;
    text-align: center;
    margin-bottom: 30px;
    /* color: #069; */
    text-decoration: none;
    font-size: 14px;
  }
  
  .form-actions {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .login-estabelecimento-form button {
    /* background-color: #069; */
    width: 100%;
    /* color: #fff; */
    border: none;
    padding: 10px 20px;
    border-radius: 3px;
    cursor: pointer;
    margin-bottom: 10px;
  }
  
  span {
    /* color: #069; */
    cursor: pointer;
    font-size: 14px;
  }


  @media only screen and (max-width: 600px) {
    .login-estabelecimento-form {
      width: 80%;
    }
  }
  